import React from 'react';
import tw from 'twin.macro';
import { css } from '@emotion/react';
import { Link } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';
import { DateTime } from 'luxon';
import Obfuscate from 'react-obfuscate';
import { BtnPrimary } from './scaffolds';

interface FooterEngProps {
  logo?: FluidObject;
  dirjenLogo?: FluidObject;
  whatsappLink?: string;
}

const FooterEng: React.FC<FooterEngProps> = (props: FooterEngProps) => {
  return (
    <footer tw="text-white">
      <div tw="bg-blue-black px-4">
        <div tw="container">
          <div tw="grid grid-cols-none lg:grid-cols-4 gap-6 py-12 lg:mx-32">
            <div tw="lg:col-span-2">
              <Img
                alt="Easybiz"
                tw="mb-4"
                fluid={props.logo as FluidObject}
                css={css`
                  width: 130px;
                  @media (max-width: 1024px) {
                    width: 68px;
                  }
                `}
              />
              <p tw="text-sm">
                Easybiz is a subsidiary of Hukumonline.com that offers easy and convenient solutions
                in legality for doing business in Indonesia. We provide We provide business
                establishment and licensing services for SMEs and startups in Indonesia, ranging
                from the process of establishing a private limited (PT), domicile registration, to
                handling the necessary permits to start a business.
              </p>
              <hr
                css={css`
                  height: 1px;
                  border: none;
                  border-top: 1px dashed white;
                `}
              />
              <div tw="text-sm">
                PT Justika Solusi Indonesia, AD Premier Office Park 9th Floor, Jl. TB Simatupang No.
                5 Ragunan, Pasar Minggu Jakarta <br />
                <div
                  tw="flex flex-wrap"
                  css={css`
                    a {
                      ${tw`text-yellow-bright`}
                      &:hover {
                        ${tw`text-blue-lapis`}
                      }
                    }
                  `}
                >
                  <div>
                    <span tw="mr-1">📞:</span>{' '}
                    <Obfuscate
                      linkText="/"
                      className="gtm-contact-phone"
                      tw="mr-1"
                      tel={'+62 816 17 369 369'}
                    />{' '}
                  </div>
                  <div>
                    <span tw="mr-1">/</span>{' '}
                    <Obfuscate
                      linkText="/"
                      className="gtm-contact-phone"
                      tw="mr-1"
                      tel={'+62 21 227 089 03'}
                    />{' '}
                    -{' '}
                  </div>
                  <div>
                    <span tw="mr-1">📧:</span>{' '}
                    <Obfuscate
                      linkText="/"
                      className="gtm-contact-email"
                      tw="mr-1"
                      email={'halo@easybiz.id'}
                    />
                  </div>
                </div>
              </div>
              <p>
                During the COVID-19 pandemic, all consultations and document submissions are
                conducted online.
              </p>
              <BtnPrimary
                className="gtm-contact-wa"
                href={props.whatsappLink || '/hubungi-kami-via-whatsapp'}
                target="__blank"
              >
                Contact Us Here
              </BtnPrimary>
            </div>
            <div>
              <p tw="text-h4 mb-0 font-extrabold font-gilroy">Our Services</p>
              <ul
                css={css`
                  padding: 0;
                  margin: 24px 0;
                  li {
                    list-style: none;
                    margin-bottom: 12px;
                  }
                  a {
                    color: white;
                    text-decoration: none;
                    &:hover {
                      color: white;
                      text-decoration: underline;
                    }
                  }
                `}
              >
                <li>
                  <Link to="/layanan/paket-easy-business">Establishment of Private Limited Company</Link>
                </li>
                <li>
                  <Link to="/layanan/paket-pendirian-pt-perorangan">Establishment of Individual Company</Link>
                </li>
                <li>
                  <Link to="/layanan/paket-pendirian-cv">Establishment of Limited Partnership</Link>
                </li>
                <li>
                  <Link to="/layanan/paket-izin-usaha">Business Licensing Services</Link>
                </li>
                <li>
                  <Link to="/layanan/paket-oss">Online Single Submission (OSS) RBA Services</Link>
                </li>
                <li>
                  <a href="https://www.easybiz.id/layanan/paket-iumk/">Small & Micro Business Permit Package</a>
                </li>
                <li>
                  <Link to="/layanan/pendirian-yayasan-atau-perkumpulan">
                  Establishment of Foundations or Other Business Entities
                  </Link>
                </li>
                <li>
                  <Link to="/layanan/paket-lkpm">LKPM Services</Link>
                </li>
                <li>
                  <Link to="/layanan/paket-pma">Foreign Investment Registration</Link>
                </li>
                <li>
                  <Link to="/layanan/pendaftaran-merek">Trademark Registration</Link>
                </li>
                <li>
                  <Link to="/layanan/paket-virtual-office">Virtual Office Services</Link>
                </li>
              </ul>
              <p tw="text-h4 mb-2 font-extrabold font-gilroy">Consumer Complaint Services</p>
              <div
                tw="flex"
                css={css`
                  margin-top: 24px;
                `}
              >
                <div tw="mr-4">
                  <Img
                    alt="Directorate General of Consumer Protection and Trade Order Ministry of Trade of the Republic of Indonesia"
                    tw="mb-4"
                    fluid={props.dirjenLogo as FluidObject}
                    css={css`
                      width: 50px;
                    `}
                  />
                </div>
                <div
                  css={css`
                    a {
                      ${tw`text-yellow-bright`}
                      &:hover {
                        ${tw`text-blue-lapis`}
                      }
                    }
                  `}
                >
                  <p tw="mb-0 text-b3">
                    Directorate General of Consumer Protection and Trade Order <br />{' '}
                    Ministry of Trade of the Republic of Indonesia
                  </p>
                  <p tw="text-b3">
                    <span tw="mr-1">📞:</span>{' '}
                    <Obfuscate linkText="/" tw="mr-1" tel={'+62 853 1111 1010'} />
                  </p>
                </div>
              </div>
            </div>
            <div>
              <p tw="text-h4 mb-0 font-extrabold font-gilroy">Tutorial</p>
              <ul
                css={css`
                  padding: 0;
                  margin: 24px 0;
                  li {
                    list-style: none;
                    margin-bottom: 12px;
                  }
                  a {
                    color: white;
                    text-decoration: none;
                    &:hover {
                      color: white;
                      text-decoration: underline;
                    }
                  }
                `}
              >
                <li>
                  <Link to="/tentang-kami/">About Us</Link>
                </li>
                <li>
                  <a href="https://www.easybiz.id/category/faq/">FAQs</a>
                </li>
                <li>
                  <Link to="/syarat-penggunaan-layanan/">Terms and Conditions</Link>
                </li>
              </ul>
              <p tw="text-h4 mb-8 font-extrabold font-gilroy">Follow Us</p>
              <div
                tw="flex flex-wrap"
                css={css`
                  a {
                    background: white;
                    padding: 12.5px;
                    border-radius: 16px;
                    font-size: 21px;
                    line-height: 21px;
                    ${tw`text-blue-black`}
                    text-decoration: none;
                    display: block;
                    height: 46px;
                    width: 46px;
                    text-align: center;
                    margin-right: 16px;
                    margin-bottom: 16px;
                  }
                `}
              >
                <a
                  href="https://www.facebook.com/easybizID"
                  target="__blank"
                  aria-label="ikuti facebook easybiz"
                >
                  <span className="icon icon-facebook"></span>
                </a>
                <a
                  href="https://www.instagram.com/easybiz.id/?hl=en"
                  target="__blank"
                  aria-label="ikuti instagram easybiz"
                >
                  <span className="icon icon-instagram"></span>
                </a>
                <a
                  href="https://twitter.com/easybizid"
                  target="__blank"
                  aria-label="ikuti twitter easybiz"
                >
                  <span className="icon icon-twitter"></span>
                </a>
                <a
                  href="https://www.linkedin.com/company/easybiz"
                  target="__blank"
                  aria-label="ikuti linkedin easybiz"
                >
                  <span className="icon icon-linkedin"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div tw="bg-blue-black-100 px-4">
        <div tw="container py-10 text-sm">
          <div tw="lg:mx-32">
            Copyright &copy; {DateTime.local().year} Easybiz | All Rights Reserved
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterEng;
